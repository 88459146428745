<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="detail.refund_number"
    class="px-5 py-3"
    v-if="detail"
  >
    <detail-info :detail="detail"></detail-info>
    <div
      class="d-flex align-center justify-center"
      style="margin-top: 32px"
      v-if="permissions.includes('DELETE') || permissions.includes('EDIT')"
    >
      <v-btn
        color="primary"
        class="bottom--button"
        @click="approve"
        v-if="permissions.includes('EDIT') && detail.allowed_actions.includes('APPROVE')"
      >
        {{ $t('refundManager.btn.approve') }}
      </v-btn>
      <v-btn
        color="warning"
        class="bottom--button"
        @click="reject"
        v-if="permissions.includes('EDIT') && detail.allowed_actions.includes('REJECT')"
      >
        {{ $t('refundManager.btn.reject') }}
      </v-btn>
      <v-btn
        color="primary"
        class="bottom--button"
        @click="finish"
        v-if="permissions.includes('EDIT') && detail.allowed_actions.includes('COMPLETE')"
      >
        {{ $t('refundManager.btn.finish') }}
      </v-btn>
      <v-btn
        color="warning"
        class="bottom--button"
        @click="cancel"
        v-if="permissions.includes('EDIT') && detail.allowed_actions.includes('CANCEL')"
      >
        {{ $t('refundManager.btn.cancel') }}
      </v-btn>
      <base-delete-button
        :permissions="permissions"
        :id="detail.uuid"
        @deleteAction="deleteAction"
        v-if="permissions.includes('DELETE') && detail.allowed_actions.includes('DELETE')"
      ></base-delete-button>
    </div>
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
const DetailInfo = () => import('@/components/refund-management/refund/detail-info.vue');

export default {
  mixins: [HelperMixin],
  props: ['detail', 'permissions', 'allowedActions'],
  components: {
    DetailInfo,
  },
  computed: {
    ...mapState({
      basePath: (state) => state.refundManager.basePath,
    }),
  },
  methods: {
    async approve() {
      try {
        let result = await this.$swal({
          type: 'warning',
          title: this.$i18n.t('refundManager.approve.confirmT'),
          text: this.$i18n.t('refundManager.approve.confirmD'),
          showCancelButton: true,
          confirmButtonText: this.$i18n.t('general.yes'),
          cancelButtonText: this.$i18n.t('general.no'),
        });
        if (result.value) {
          await this.$store.dispatch('refundManager/approve', this.detail.uuid);
          await this.$swal(
            this.$i18n.t('general.success'),
            this.$i18n.t('refundManager.approve.successMsg'),
            'success',
          );
          this.$router.go();
        }
        // eslint-disable-next-line no-empty
      } catch (e) {
        console.log('ERROR APPROVING: ', e);
      }
    },
    async reject() {
      try {
        let result = await this.$swal({
          input: 'textarea',
          inputPlaceholder: this.$i18n.t('refundManager.reject.placeholder'),
          title: this.$i18n.t('refundManager.reject.confirmT'),
          text: this.$i18n.t('refundManager.reject.confirmD'),
          showCancelButton: true,
          confirmButtonText: this.$i18n.t('general.yes'),
          cancelButtonText: this.$i18n.t('general.no'),
        });
        if (result.value) {
          await this.$store.dispatch('refundManager/reject', {
            uuid: this.detail.uuid,
            reason: result.value,
          });
          await this.$swal(
            this.$i18n.t('general.success'),
            this.$i18n.t('refundManager.reject.successMsg'),
            'success',
          );
          this.$router.go();
        }
        // eslint-disable-next-line no-empty
      } catch (e) {
        console.log('ERROR REJECTING: ', e);
      }
    },
    async finish() {
      try {
        let result = await this.$swal({
          input: 'file',
          inputAttributes: {
            accept: 'image/*',
            'aria-label': this.$i18n.t('refundManager.finish.confirmD'),
          },
          title: this.$i18n.t('refundManager.finish.confirmT'),
          text: this.$i18n.t('refundManager.finish.confirmD'),
          showCancelButton: true,
          confirmButtonText: this.$i18n.t('general.yes'),
          cancelButtonText: this.$i18n.t('general.no'),
        });
        if (result.value) {
          const reader = new FileReader();
          reader.onload = async (e) => {
            if (e.target.result) {
              const blob = this.dataURLToBlob(e.target.result);
              let response = await this.$store.dispatch('refundManager/uploadFile', {
                blob: blob,
                filename: result.value.name,
              });
              const photoUuid = response.data.data.photo_uuid;
              console.log(`PHOTO UUID: ${photoUuid}`);
              if (photoUuid) {
                await this.$store.dispatch('refundManager/finish', {
                  uuid: this.detail.uuid,
                  proofFileUuid: photoUuid,
                });
                await this.$swal(
                  this.$i18n.t('general.success'),
                  this.$i18n.t('refundManager.finish.successMsg'),
                  'success',
                );
                this.$router.go();
              }
            }
          };
          reader.readAsDataURL(result.value);
        }
        // eslint-disable-next-line no-empty
      } catch (e) {
        console.log('ERROR FINISHING: ', e);
      }
    },
    async cancel() {
      try {
        let result = await this.$swal({
          input: 'textarea',
          inputPlaceholder: this.$i18n.t('refundManager.cancel.placeholder'),
          title: this.$i18n.t('refundManager.cancel.confirmT'),
          text: this.$i18n.t('refundManager.cancel.confirmD'),
          showCancelButton: true,
          confirmButtonText: this.$i18n.t('general.yes'),
          cancelButtonText: this.$i18n.t('general.no'),
        });
        if (result.value) {
          await this.$store.dispatch('refundManager/cancel', {
            uuid: this.detail.uuid,
            reason: result.value,
          });
          await this.$swal(
            this.$i18n.t('general.success'),
            this.$i18n.t('refundManager.cancel.successMsg'),
            'success',
          );
          this.$router.go();
        }
        // eslint-disable-next-line no-empty
      } catch (e) {
        console.log('ERROR CANCELING: ', e);
      }
    },
    async deleteAction(uuid) {
      try {
        await this.$store.dispatch('refundManager/delete', uuid);
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('refundManager.delete.successMsg'),
          'success',
        );
        this.$router.back();
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
  },
};
</script>
